@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f0f0f0;
  font-family: Montserrat;
}

a {
  text-decoration: none;
  color: #b00041;
}

a:hover {
  color: #b00041;
}

.card {
  @apply flex flex-col bg-white rounded-3xl px-7 py-5 m-5 dark:bg-darkcard dark:text-white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-style: none;
}

.box {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-style: none;
}

.card h1 {
  color: #b00041;
  @apply text-lg mb-2;
}

.sidebar-links {
  @apply flex gap-3 items-center hover:bg-red-100 hover:text-[#b00041] hover:rounded-xl p-3 hover:cursor-pointer w-full;
}

.sidebar-links.active {
  @apply bg-[#b00041] dark:bg-darkdiv text-white  gap-3 rounded-xl;
}

.my-audits-item {
  @apply text-center py-1 px-2 rounded-full;
}

.navlink-button {
  @apply px-7 py-2 rounded-xl bg-ruby-red text-white hover:ring-2;
}

.navlink-button-hover {
  @apply hover:ring-ruby-red  hover:bg-white hover:text-ruby-red;
}

.searchbar-focus {
  @apply focus:border-ruby-red focus:outline-none focus:ring-0;
}

/* calendar styles start */
.fc h2 {
  margin: 0;
  font-size: 16px;
}

.fc ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

.fc li {
  margin: 1.5em 0;
  padding: 0;
}

.fc {
  width: 100%;
  max-height: 90%;
  background-color: white !important;
  padding: 2rem !important;
  border-radius: 1.5rem !important;
}

.fc-toolbar-title {
  /* the header title */
  font-weight: normal !important;
  color: #b00030 !important;
}

.fc-button {
  background-color: #b00030 !important;
  color: white !important;
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in-out !important;
  padding: 0.4rem 0.6rem 0.4rem 0.6rem !important;
  height: 2.7rem !important;
  width: 3.9rem !important;
  font-size:small !important;
  font-weight: 800 !important;
}

.fc-prev-button {
  width:2.5rem !important;
}

.fc-next-button {
  width: 2.5rem !important;
}
.fc-button-group {
  display: flex;
  justify-content: space-between;
}

.fc-button:hover {
  background-color: #ffffff !important;
  color: #b00030 !important;
  border: 1px solid #b00030 !important;
  box-shadow: none !important;
  outline: 0 !important;
  transform: none !important;
}

.fc-button-active {
  background-color: #ffffff !important;
  color: #b00030 !important;
  border: 1px solid #b00030 !important;
  outline: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
}

/* Add space between the icons */
.ql-toolbar button {
  margin-right: 0.75rem; /* Adjust as needed */
}


/*Settings icon color */
.settings-icon {
  color: black !important; 
}

.button-container a {
  flex-direction: row;
  width: 87%; 
  box-sizing: border-box;
  justify-content: initial;
}

.button-container a.active{
  background-color: #b00030;
  color: white;
}
.styled-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 30px);;
  padding: 5px 12px;
  margin: 6px 15px;
  border-radius: 12px;
  text-decoration: none;
  font-size: 14px;
  color: inherit;
  background-color: #f0f0f0;
  @apply dark:bg-darkcard dark:text-white;
}

.styled-button:hover {
  background-color: #f0f0f0;
  @apply dark:bg-darkdiv dark:text-white;
}
.popup-placeholder-names{

  color: black;
  background-color: #f0f0f0;
  border-radius: 15px;
  margin-bottom: 10px;
  @apply dark:bg-darkbg dark:text-white;

}
 .popup-placeholder-btn{
  color: #3b82f6;
  background-color: #f0f0f0;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 10px;
  width: 570px;
  @apply dark:bg-darkbg ;
  display: flex;

 }
 .popup-placeholder-btn:hover{
  color: #1d4ed8
 }


 .profile-card{
  margin: auto;
 }
 .profile-component-count{
  background-color: #f0f0f0;
  @apply dark:bg-darkbg
 }
.popup-placeholder{

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 570px;
  border-radius: 15px;
  margin-bottom: 10px;
  color: black;
  background-color: #f0f0f0;
  @apply dark:bg-darkbg dark:text-white;
}

.styled-button.active-link {
  background-color: #d0d0d0;
  @apply dark:bg-darkdiv dark:text-white;
}

.styled-menu-item {
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin: 5px 0;
  padding: 10px;
  background-color: transparent;
  @apply dark:text-white;
}

.styled-menu-item:hover {
  background-color: transparent;
  @apply dark:bg-darkdiv dark:text-white;
}


.styled-navlink {
  &.active-link {
    background-color: #f0f0f0;
    border-radius: 12px;
    @apply dark:bg-darkdiv dark:text-white;
  }
}


.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  overflow: hidden;
  @apply dark:bg-darkcard dark:text-white;
}

.sidebar-container.collapsed {
  width: 80px;
}

.profile {
  background-color: #111;
  color: #ffffff;
  padding: 20px;
  margin-left: 250px; /* Space for the sidebar */
  height: 100vh;

}

.profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture img {
  border-radius: 50%;
  margin-right: 20px;
}

.profile-details p {
  margin: 10px 0;
}



.profile-actions button {
  background-color: #b00030;
  border: none;
  color: white;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.profile-actions button:hover {
  background-color: #b00030;
}


.profile-section {
  display: flex;
  align-items: center;
  padding: 3px;
  background-color: #ffffff;
  position: absolute;
  bottom: 5px;
  width: 100%;
  justify-content: space-between;
  @apply dark:bg-darkcard dark:text-white;
}

.title {
  font-size: 35px;
  font-weight: 1000;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 5px;
  color: #000;
  text-shadow: 10px 10px 10px 20px (0, 0, 0, 1);
  @apply dark:text-white;
}

.subtitle {
  font-size: 22px;
  font-weight: 1500;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 20px;
  color: #000;
  text-shadow: 10px 10px 10px 20px (0, 0, 0, 1);
  @apply dark:text-white;
}

.section-title {
  font-size: 12px;
  font-weight: bold;
  margin: 3px 10px;
  margin-top: 10px;
  color: black;
  text-align: left;
  padding-left: 10px;
  @apply dark:text-white;
}

.search-wrapper {
  position: relative;
  width: 100%;
  margin: 5px auto;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none; 
  @apply dark:text-darktext;
}

.search-bar {
  width: calc(100% - 35px); 
  padding: 8px 8px 8px 35px;
  margin: 5px auto;
  border: 1px solid #ccc;
  border-radius: 15px;
  align-items: center;
  font-size: 15px;
  display: block;
  @apply dark:bg-darkcard dark:text-darktext dark:border-darktext;
}

.search-bar-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.styled-icon {
  display: flex;
  font-size: 18px;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  
  @apply dark:text-white;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}
.sidebar-wrapper {
  position: relative;
  
}
.settings-popup {
  position: absolute;
  bottom: 30px;
  left: 260px;
  width: 200px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  z-index: 1000;
  @apply dark:bg-darkcard dark:text-darktext;
}


.popup-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6px 1px;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 12px;
  text-decoration: none;
  font-size: 14px;
  color: inherit;
  gap: 10px;
  @apply dark:text-white;
}

.popup-button:hover {
  background-color: #E1E1E2;
  @apply dark:bg-darkdiv dark:text-white;
 
}

.toggle-icon {
  cursor: pointer;
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.styled-menu-item:hover .toggle-icon {
  opacity: 1; /* Show on hover of the parent */
}

.toggle-icon:hover {
  background-color: #e0e0e0;
  border-radius: 50%;
  color: #b00041;
}

.toggle-icon.collapsed {
  transform: rotate(180deg);
}

.toggle-icon {
  transition: transform 0.3s ease;
}

.main-content {
  padding: 0px;
  background-color: #f5f5f5;
  height: 100vh;
  transition: margin-left 0.3s ease;
}

.main-content.collapsed {
  margin-left: 80px;
}

.main-content.expanded {
  margin-left: 0px;
}

.main-content.full-width {
  margin: 0; 
  width: 100%;
  height: 100%;
  padding:0;
}
.custom-hr {
  width: 100%;
  margin: 10px auto;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.styled-person-icon:hover {
  background-color: #f0f0f0;
  border-radius: 7px;
  cursor: pointer;
  transform: scale(1.2);
 
  @apply dark:bg-darkdiv dark:text-white;
}



.profile-section {
  display: flex;
  align-items: center;
  padding: 15px 15px; /* Adjust padding for better spacing */
  background-color: #ffffff;
  position: absolute;
  bottom: 0; /* Stick to the bottom */
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px; 
  border-top: 1px solid rgba(0, 0, 0, 0.2); 
}

.profile-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px; /* Adjust margin for spacing between icon and text */
}

.profile-name {
  font-size: 14px;
  color: #000;
  margin: 2; 
  margin-bottom: -4;
  @apply dark:text-white;
}

.profile-title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin: 0; 
  @apply dark:text-white;
}
.styled-person-icon {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  padding: auto;
  transition: transform 0.2s ease, background-color 0.2s ease;
}
.styled-settings-icon {
  font-size: 30px;
  cursor: pointer;
  margin-left: 15px;
  height: 35px;
  width: 35px;
  padding: auto;
  transition: transform 0.2s ease, background-color 0.2s ease;
}
.styled-settings-icon:hover {
  background-color: #f0f0f0;
  border-radius: 7px;
  
  transform: scale(1.2);
 
  @apply dark:bg-darkdiv dark:text-white;
}

.card-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to a new row */
  gap: 10px; /* Space between cards */
  max-width: 650px; /* Adjust based on desired layout */
}

.sub-card {
  background-color: #b11b54; /* Card color */
  color: white;
  width: 285px;
  height: 140px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  position: relative; 
}
.icon{
  position: absolute; 
  top: 5px;   
  left: 15px;   
  font-size: 24px;
}
.icon-right{
  position: absolute; 
  top: 5px;    
  right: 15px;   
  font-size: 24px;
}
.sub-card-title{
  position: absolute; 
  top: 85px;   
  left: 15px;   
  font-size: 18px;
}
.fav-title{
  @apply  dark:text-white;
}

/* MobilePage.css */

.mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  @apply dark:bg-darkcard dark:text-darktext;
 
}
.hexagon-image {
  width: 100px; 
  height: auto;
  margin-bottom: 100px;
  transform: rotate(30deg);
  
}


.mobile-button {
  width: 200px;
  padding: 12px;
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #a6193f;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mobile-button:hover {
  background-color: #8e0b2e;
}

.mobile-button:active {
  background-color: #8e0b2e;
}

@layer components {
  .input-field {
    font-family: 'Nunito', sans-serif;
    @apply text-base text-gray-800 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full;
  }
}